import * as React from 'react';
import { useEffect} from 'react';

import Box from '@mui/material/Box';
import CitySelect from "../cityes/CitySelect";
import Divider from "@mui/material/Divider";
import {
    Button, FormControl, FormHelperText, Input, InputAdornment,
    Paper, Switch, Table, TableBody, TableCell, tableCellClasses,
    TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../app/hooks/appHooks";
import {styled} from "@mui/material/styles";
import {setError, setOkMessage} from "../../app/reducers/message/globalMessageSlice";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useCityController} from "../../app/controllers/useCityController";
import {ICitySettings} from "../../app/model/city/ICitySettings";
import {IDistributionOrdersSettings} from "../../app/model/order/IDistributionOrdersSettings";
import {useDistributionOrdersController} from "../../app/controllers/useDistributionOrdersController";


export default function DistributionOrdersSettings() {
    const {currentCity } = useAppSelector((state) => state.city.state)
    const [loading, setLoading] = React.useState(false)
    const {getDistributionSettings, updateSettings} = useDistributionOrdersController()
    const dispatch = useAppDispatch()
    const {
        register,
        handleSubmit,
        reset,
        control ,
        formState: { errors, defaultValues },
    } = useForm<IDistributionOrdersSettings>({defaultValues:{
            cityId:0,
            isEnable:false,
            sendUndistributedOrdersToGroup:false,
            groupWithOrderActive:false,
            isGroupFreeAutoCaptureOffActive:false,
            isGroupFreeAutoCaptureOnActive:false,
            amountDistributionCycles:2,
            delayProcessingOrder: 30,
            groupFreeAutoCaptureOffSequenceNumber: 999,
            groupFreeAutoCaptureOnSequenceNumber: 999,
            groupWithOrderSequenceNumber: 999,
            maxDistanceToClient: 10,
            maxDriversListSize: 10,
            scanPeriod: 10,
            clientDropOffTimeInSeconds: 20,
            distanceFactorForDriverWithOrder: 1
        }})

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
            padding:"3px 15px",
        },
    }));

    useEffect(() => {
        getSettings(currentCity.id)
    },[])

    async function getSettings(cityId: number){
        setLoading(true)
        if (cityId === 0){
            reset(defaultValues)
            setLoading(false)
            return
        }
        const settings = await getDistributionSettings(cityId)
        if(settings){
            reset(settings)
            setLoading(false)
        }
    }

    const onSubmit: SubmitHandler<IDistributionOrdersSettings> = (data) => {
        setLoading(true)
        data.cityId = currentCity.id
        updateSettings(data).then(value => {
                dispatch(setOkMessage({title:'Оновлення налаштувань розподілу замовлень збережені.', message:'Налаштування для міста ' + currentCity.name}))
                setLoading(false)
        }).catch(reason => {
            dispatch(setError({title:'Помилка оновлення налаштувань розподілу замовлень для міста.', message:(reason.response?.data as any).message}))
        })
    }

    const cityFilterHandler = (cityId: number) => {
        getSettings(cityId)
    }

    return (
        <Box sx={{width: '100%', height: '100%', mt: 2, display: 'flex', flexDirection: 'column', backgroundColor: 'grey.A100'}}>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <CitySelect onCityChange={cityFilterHandler} filters={undefined}/>
            </Box>
            <Divider variant="middle" sx={{mt: 2, mb: 2, ml: 8, mr: 8}}/>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box sx={{display: 'flex', flexDirection: 'column', pl: '1%', pr: '1%', width:'100%', maxWidth: 800 }}>
                    <Typography sx={{ml: 4}}>
                        Налаштування для міста
                    </Typography>
                    <TableContainer sx={{ maxWidth: 800 }} component={Paper}>
                        <Table sx={{ minWidth: 600, maxWidth: 800 }} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Назва</StyledTableCell>
                                    <StyledTableCell align="right">Налаштування</StyledTableCell>
                                    <StyledTableCell align="right">Активно</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {/* is Enable */}
                                <TableRow key={1} >
                                    <StyledTableCell component="th" scope="row">Застосовувати автоматичний розподіл замовлень в місті</StyledTableCell>
                                    <StyledTableCell align="right" >
                                        <Controller
                                            name="isEnable"
                                            control={control}
                                            disabled={currentCity.id === 0}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch {...field} checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                    </StyledTableCell>
                                </TableRow>

                                {/* is Enable */}
                                <TableRow key={2} >
                                    <StyledTableCell component="th" scope="row">Відправляти не розподілені замовлення в групу водіїв</StyledTableCell>
                                    <StyledTableCell align="right" >
                                        <Controller
                                            name="sendUndistributedOrdersToGroup"
                                            control={control}
                                            disabled={currentCity.id === 0}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch {...field} checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                    </StyledTableCell>
                                </TableRow>

                                {/* max Distance To Client */}
                                <TableRow key={3} >
                                    <StyledTableCell component="th" scope="row">Максимальна дистанція до клієнта</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''}
                                                inputProps={{step: "0.1"}}
                                                {...register("maxDistanceToClient", {
                                                    required: 'Потрібно ввести дистанцію',
                                                    min: 0.1
                                                })}
                                                error={!!errors.maxDistanceToClient}
                                                endAdornment={<InputAdornment position="end">км</InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.maxDistanceToClient?.message ? errors.maxDistanceToClient?.message : 'дистанція'}
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                    </StyledTableCell>
                                </TableRow>

                                {/* max Drivers List Size */}
                                <TableRow key={4} >
                                    <StyledTableCell component="th" scope="row">Максимальний розмір черги водіїв</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''}
                                                inputProps={{step: "1"}}
                                                {...register("maxDriversListSize", {
                                                    required: 'Потрібно ввести розмір',
                                                    min: 1
                                                })}
                                                error={!!errors.maxDriversListSize}
                                                endAdornment={<InputAdornment position="end"></InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.maxDriversListSize?.message ? errors.maxDriversListSize?.message : 'розмір'}
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                    </StyledTableCell>
                                </TableRow>

                                {/* delay Processing Order */}
                                <TableRow key={5} >
                                    <StyledTableCell component="th" scope="row">Затримка розподілу замовлення (аби водії самі могли забрати замовлення)</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''}
                                                inputProps={{step: "1"}}
                                                {...register("delayProcessingOrder", {
                                                    required: 'Потрібно ввести затримку',
                                                    min: 1
                                                })}
                                                error={!!errors.delayProcessingOrder}
                                                endAdornment={<InputAdornment position="end">сек</InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.delayProcessingOrder?.message ? errors.delayProcessingOrder?.message : 'затримка'}
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                    </StyledTableCell>
                                </TableRow>

                                {/* amount Distribution Cycles */}
                                <TableRow key={6} >
                                    <StyledTableCell component="th" scope="row">Кількість спроб розподілити замовлення водію</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''}
                                                inputProps={{step: "1"}}
                                                {...register("amountDistributionCycles", {
                                                    required: 'Потрібно ввести кількість',
                                                    min: 1
                                                })}
                                                error={!!errors.amountDistributionCycles}
                                                endAdornment={<InputAdornment position="end">од</InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.amountDistributionCycles?.message ? errors.amountDistributionCycles?.message : 'спроби'}
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                    </StyledTableCell>
                                </TableRow>

                                {/* scanPeriod */}
                                <TableRow key={7} >
                                    <StyledTableCell component="th" scope="row">Затримка між призначеннями водіїв</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''}
                                                inputProps={{step: "1"}}
                                                {...register("scanPeriod", {
                                                    required: 'Потрібно ввести час в секундах',
                                                    min: 3
                                                })}
                                                error={!!errors.scanPeriod}
                                                endAdornment={<InputAdornment position="end">сек</InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.scanPeriod?.message ? errors.scanPeriod?.message : 'кількість секунд'}
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                    </StyledTableCell>
                                </TableRow>

                                {/* client Drop Off Time In Seconds */}
                                <TableRow key={8} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">Час на висаджування клієнта</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''}
                                                inputProps={{step: "1"}}
                                                {...register("clientDropOffTimeInSeconds", {
                                                    required: 'Потрібно ввести час в секундах',
                                                    min: 3
                                                })}
                                                error={!!errors.scanPeriod}
                                                endAdornment={<InputAdornment position="end">сек</InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.scanPeriod?.message ? errors.scanPeriod?.message : 'кількість секунд'}
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                    </StyledTableCell>
                                </TableRow>


                                {/* Group Free Auto Capture On */}
                                <TableRow key={9} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">Пріорітет групи без замовлення з увімкненним автозахопленням</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "1"}}
                                                {...register("groupFreeAutoCaptureOnSequenceNumber", {
                                                    required: 'Потрібно ввести значення пріорітету',
                                                    min:0 })}
                                                error={!!errors.groupFreeAutoCaptureOnSequenceNumber}
                                                endAdornment={<InputAdornment position="end"></InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.groupFreeAutoCaptureOnSequenceNumber?.message ? errors.groupFreeAutoCaptureOnSequenceNumber?.message : 'пріорітет' }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" >
                                        <Controller
                                            name="isGroupFreeAutoCaptureOnActive"
                                            control={control}
                                            disabled={currentCity.id === 0}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch {...field} checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>

                                {/* Group Free Auto Capture Off */}
                                <TableRow key={10} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">Пріорітет групи без замовлення з вимкненним автозахопленням</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "1"}}
                                                {...register("groupFreeAutoCaptureOffSequenceNumber", {
                                                    required: 'Потрібно ввести значення пріорітету',
                                                    min:0 })}
                                                error={!!errors.groupFreeAutoCaptureOffSequenceNumber}
                                                endAdornment={<InputAdornment position="end"></InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.groupFreeAutoCaptureOffSequenceNumber?.message ? errors.groupFreeAutoCaptureOffSequenceNumber?.message : 'пріорітет' }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" >
                                        <Controller
                                            name="isGroupFreeAutoCaptureOffActive"
                                            control={control}
                                            disabled={currentCity.id === 0}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch {...field} checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>

                                {/* Group With Order */}
                                <TableRow key={11} >
                                    <StyledTableCell component="th" scope="row">Пріорітет групи з замовленням в стані руху до пункту висадки</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "1"}}
                                                {...register("groupWithOrderSequenceNumber", {
                                                    required: 'Потрібно ввести значення пріорітету',
                                                    min:0 })}
                                                error={!!errors.groupWithOrderSequenceNumber}
                                                endAdornment={<InputAdornment position="end"></InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.groupWithOrderSequenceNumber?.message ? errors.groupWithOrderSequenceNumber?.message : 'пріорітет' }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" sx={{borderBottom: 0}}>
                                        <Controller
                                            name="groupWithOrderActive"
                                            control={control}
                                            disabled={currentCity.id === 0}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch {...field} checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>

                                {/* distance factor for a driver with order */}
                                <TableRow key={12} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">Коефіцієнт відстані для водія з замовленням в стані руху до пункту висадки</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "0.01"}}
                                                {...register("distanceFactorForDriverWithOrder", {
                                                    required: 'Потрібно ввести значення',
                                                    min:0.01,
                                                    max: 5
                                                })}
                                                error={!!errors.groupWithOrderSequenceNumber}
                                                endAdornment={<InputAdornment position="end"></InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.groupWithOrderSequenceNumber?.message ? errors.groupWithOrderSequenceNumber?.message : 'коефіцієнт' }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" >
                                    </StyledTableCell>
                                </TableRow>

                                {/* Group With Order and waiting client state*/}
                                <TableRow key={13} >
                                    <StyledTableCell component="th" scope="row">Пріорітет групи з замовленням в стані очікування клієнта</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "1"}}
                                                {...register("groupWithOrderAndWaitingClientSequenceNumber", {
                                                    required: 'Потрібно ввести значення пріорітету',
                                                    min:0 })}
                                                error={!!errors.groupWithOrderSequenceNumber}
                                                endAdornment={<InputAdornment position="end"></InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.groupWithOrderSequenceNumber?.message ? errors.groupWithOrderSequenceNumber?.message : 'пріорітет' }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" sx={{borderBottom: 0}}>
                                        <Controller
                                            name="groupWithOrderAndWaitingClientActive"
                                            control={control}
                                            disabled={currentCity.id === 0}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch {...field} checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>

                                {/* distance factor for a driver with order and waiting client state */}
                                <TableRow key={14} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">Коефіцієнт відстані для водія з замовленням в стані очікування клієнта</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "0.01"}}
                                                {...register("distanceFactorForDriverWithOrderAndWaitingClient", {
                                                    required: 'Потрібно ввести значення',
                                                    min:0.01,
                                                    max: 5
                                                })}
                                                error={!!errors.groupWithOrderSequenceNumber}
                                                endAdornment={<InputAdornment position="end"></InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.groupWithOrderSequenceNumber?.message ? errors.groupWithOrderSequenceNumber?.message : 'коефіцієнт' }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" >
                                    </StyledTableCell>
                                </TableRow>

                                {/* Group With Order and intermediate point*/}
                                <TableRow key={15} >
                                    <StyledTableCell component="th" scope="row">Пріорітет групи з замовленням в якому є проміжна точка</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "1"}}
                                                {...register("groupWithOrderWithIntermediatePointSequenceNumber", {
                                                    required: 'Потрібно ввести значення пріорітету',
                                                    min:0 })}
                                                error={!!errors.groupWithOrderSequenceNumber}
                                                endAdornment={<InputAdornment position="end"></InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.groupWithOrderSequenceNumber?.message ? errors.groupWithOrderSequenceNumber?.message : 'пріорітет' }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" sx={{borderBottom: 0}}>
                                        <Controller
                                            name="groupWithOrderWithIntermediatePointActive"
                                            control={control}
                                            disabled={currentCity.id === 0}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch {...field} checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>

                                {/* distance factor for a driver with order and intermediate point */}
                                <TableRow key={16} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">Коефіцієнт відстані для водія з замовленням в якому є проміжна точка</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "0.01"}}
                                                {...register("distanceFactorForDriverWithOrderWithIntermediatePoint", {
                                                    required: 'Потрібно ввести значення',
                                                    min:0.01,
                                                    max: 5
                                                })}
                                                error={!!errors.groupWithOrderSequenceNumber}
                                                endAdornment={<InputAdornment position="end"></InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.groupWithOrderSequenceNumber?.message ? errors.groupWithOrderSequenceNumber?.message : 'коефіцієнт' }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" >
                                    </StyledTableCell>
                                </TableRow>

                                {/* Group With Order and move to client*/}
                                <TableRow key={17} >
                                    <StyledTableCell component="th" scope="row">Пріорітет групи з замовленням в стані прямування до клієнта</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "1"}}
                                                {...register("groupWithOrderMoveToClientStateSequenceNumber", {
                                                    required: 'Потрібно ввести значення пріорітету',
                                                    min:0 })}
                                                error={!!errors.groupWithOrderSequenceNumber}
                                                endAdornment={<InputAdornment position="end"></InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.groupWithOrderSequenceNumber?.message ? errors.groupWithOrderSequenceNumber?.message : 'пріорітет' }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" sx={{borderBottom: 0}}>
                                        <Controller
                                            name="groupWithOrderMoveToClientStateActive"
                                            control={control}
                                            disabled={currentCity.id === 0}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch {...field} checked={field.value}
                                                        onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>

                                {/* distance factor for a driver with order  and move to client*/}
                                <TableRow key={18} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">Коефіцієнт відстані для водія з замовленням в стані прямування до клієнта</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{width: "13ch", margin:0}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "0.01"}}
                                                {...register("distanceFactorForDriverWithOrderMoveToClientState", {
                                                    required: 'Потрібно ввести значення',
                                                    min:0.01,
                                                    max: 5
                                                })}
                                                error={!!errors.groupWithOrderSequenceNumber}
                                                endAdornment={<InputAdornment position="end"></InputAdornment>}
                                            />
                                            <FormHelperText>
                                                {errors.groupWithOrderSequenceNumber?.message ? errors.groupWithOrderSequenceNumber?.message : 'коефіцієнт' }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" >
                                    </StyledTableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button
                        sx={{width: '200px', alignSelf: 'end', mt: 2, mb:2}}
                        variant="contained"
                        type="submit"
                    >Зберегти</Button>
                </Box>
            </form>
        </Box>
    );
}