import * as React from 'react';

import Box from '@mui/material/Box';
import {
    converterStringTimestampToDate,
    dateFormatTimeLast
} from "../../app/utils/DataUtil";
import TableEmptyOverlay from "../overlay/TableEmptyOverlay";
import StyledOperationTable from "../reports/StyledOperationTable";
import {useCallback, useEffect, useState} from "react";
import {
    GridColDef,
    GridValueFormatterParams,
    GridValueGetterParams,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridColumnVisibilityModel,
    GridToolbarExport, GridRenderCellParams, GridRowModel, GridRowClassNameParams, gridClasses, GridColumnHeaderParams,
} from "@mui/x-data-grid-pro";
import {IOrdersReport} from "../../app/model/order/IOrdersReport";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Tooltip, Typography} from "@mui/material";
import {useOrdersReportController} from "../../app/controllers/useOrdersReportController";
import {IFilters} from "../../app/model/pageRequestQuery/IFilters";
import dayjs from "dayjs";
import {IRequestQueryMultiple} from "../../app/model/pageRequestQuery/IRequestQueryMultiple";
import {IFilter} from "../../app/model/pageRequestQuery/IFilter";
import DateTimeRange from "../dateTimeRange/DateTimeRange";
import CitySelect from "../cityes/CitySelect";
import {IDistributedOrdersReport} from "../../app/model/order/IDistributedOrdersReport";
import {useDistributedOrdersReportController} from "../../app/controllers/useDistributedOrdersReportController";
import WifiIcon from "@mui/icons-material/Wifi";
import {grey} from "@mui/material/colors";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import GpsOffIcon from '@mui/icons-material/GpsOff';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import PushPinIcon from '@mui/icons-material/PushPin';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const dateTimeConverter = (params: GridValueGetterParams<any, any>):Date | undefined => {
    return converterStringTimestampToDate(params.row[params.field])
}

const statusConverter = (params: GridValueGetterParams<any, any>):string | undefined => {
    const rawStatus: string = params.value
    switch (rawStatus) {
        case "NOT_DISTRIBUTED":
            return "не розподілено"
        case "ORDER_DISTRIBUTION_STOP":
            return "розподілення зупинено"
        case "NO_DRIVERS_FOUND":
            return "водіїв не знайдено"
        case "ORDER_DISTRIBUTION_ERROR":
            return "помилка розподілення"
        case "ORDER_DISTRIBUTED":
            return "розподілено"
        default:
            return "невідомий параметр"

    }
}

const renderOrderLink = (params: GridRenderCellParams<any, string>) => {
    const link = "https://admin.ontaxi.com.ua/orders/" + params.value + "?tab=1"
    return <Link href={link} underline="hover" target="_blank" sx={{overflow:'hidden'}}>
        {params.value}
    </Link>
}

const renderDriverLink = (params: GridRenderCellParams<any, string>) => {
    const link = "https://admin.ontaxi.com.ua/drivers/" + params.value + "?tab=3"
    return <Link href={link} underline="hover" target="_blank" sx={{overflow:'hidden'}}>
        {params.value}
    </Link>
}

const currencyFormatter = new Intl.NumberFormat('ru');

const formatSum = (params: GridValueFormatterParams<number>) => {
    if (params.value === 0){
        return ''
    }
    return currencyFormatter.format(params.value)
}


const formatDate = (params: GridValueFormatterParams<Date>) => {
    return dateFormatTimeLast(params.value)
}

function NoRowsOverlay() {
    return (
        <TableEmptyOverlay text={'Нема операцій'}/>
    );
}

function styles(params: GridRowClassNameParams<any>):string{
    const operation = params.row as IOrdersReport
    if (operation.errorItem === true){
        return 'row-theme--error'
    }
    return ''
}

interface IProps {
    isAutoHeight:boolean
    fullHeight:number,
}

export default function DistributedOrdersReportTable({isAutoHeight, fullHeight}: IProps) {
    const [isPending, setPending] = useState(false)
    const [report, setReport] = useState<IDistributedOrdersReport[]>([])
    const [columnVisibleModel, setColumnVisibleModel] = useState<GridColumnVisibilityModel>({id:true})
    const {getReportByDateRange} = useDistributedOrdersReportController()
    const [dateRangeFilter, setRangeFilter] = useState<IFilters>({
        filters:[
            {field:'dateTime', operator:'after', value:dayjs(new Date()).set('hour',0).set('minute',0).format('YYYY-MM-DDTHH:mm')},
            {field:'dateTime', operator:'before', value:dayjs(new Date()).set('hour',23).set('minute',59).format('YYYY-MM-DDTHH:mm')},
        ],  operator:'and'
    })

    const columns: GridColDef[] = [
        { field: 'rowNumber', headerName: '#', flex: 1, minWidth: 40, maxWidth: 60, sortable: false, filterable:false, type: 'string', valueGetter: params => params.api.getRowIndexRelativeToVisibleRows(params.id) + 1},
        { field: 'id', headerName: 'id замовлення', flex: 1, minWidth: 120, maxWidth:200, type: 'string', renderCell: renderOrderLink},
        { field: 'driverId', headerName: 'id водія', flex: 1, minWidth: 120, maxWidth: 200, type: 'string', renderCell: renderDriverLink},
        { field: 'state', headerName: 'Статус', flex: 1, minWidth: 180, maxWidth: 200, type: 'string', valueGetter: statusConverter},
        { field: 'attempts', headerName: 'кількість спроб', flex: 1, minWidth: 120, maxWidth: 140, type: 'number'},
        { field: 'driversOnlineByMaxDistance', headerName: 'водіїв онлайн в межах максимального радіусу розподілення', width:120, type: 'number',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='водіїв онлайн в межах максимального радіусу розподілення'>
                    <WifiIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'driverWithoutOrderAutoCaptureOn', headerName: 'авто захопленя ON', width:120, type: 'number',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='авто захопленя ON'>
                    <GpsFixedIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'driverWithoutOrderAutoCaptureOff', headerName: 'авто захопленя OFF', width:120, type: 'number',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='авто захопленя OFF'>
                    <GpsOffIcon sx={{ fontSize: 18, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'driversWithOrder', headerName: 'з замовленнями', width:120, type: 'number',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='з замовленнями на шляху до місця висадки'>
                    <WhereToVoteIcon sx={{ fontSize: 20, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'driversWithOrderWaitingClientState', headerName: 'з замовленнями очікує клієнта', width:120, type: 'number',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='з замовленнями очікує клієнта'>
                    <PersonPinCircleIcon sx={{ fontSize: 20, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'driversWithOrderWithIntermediatePoint', headerName: 'з замовленнями з проміжною точкою', width:120, type: 'number',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='з замовленнями з проміжною точкою'>
                    <PushPinIcon sx={{ fontSize: 20, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'driversWithOrderMoveToClientState', headerName: ' на шляху до клієнта', width:120 , type: 'number',
            renderHeader: (params: GridColumnHeaderParams) => (
                <Tooltip title='з замовленнями на шляху до клієнта'>
                    <NotListedLocationIcon sx={{ fontSize: 20, color: grey[700] }}/>
                </Tooltip> )
        },
        { field: 'dateTime', headerName: 'Дата', flex: 1, minWidth: 180, maxWidth: 250, type: 'dateTime', valueGetter: dateTimeConverter, valueFormatter: formatDate },

    ];

    useEffect(() => {
        fetchOperations({filter: dateRangeFilter})
    }, []);

    async function fetchOperations(query: IRequestQueryMultiple){
        setPending(true)
        await fetchByDateRange(query)
        setPending(false)
    }

    async function fetchByDateRange(query: IRequestQueryMultiple) {
        const report = await getReportByDateRange(query);
        setReport(report)
    }

    const rangeFilterHandler = (dateRangeFilter: IFilters) => {
        setRangeFilter(dateRangeFilter)
    }

    function insertCityFilter(cityId: number, filter: IFilters): IFilters {
        if (filter && filter.filters.length > 0) {
            const newFilters = [...filter.filters]
            if (cityId !== 0) {
                const cityFilter = newFilters.filter(value => value.field === 'cityId');
                if (cityFilter.length === 0) {
                    const filter: IFilter = {field: 'cityId', operator: '=', value: cityId}
                    newFilters.push(filter)
                } else {
                    cityFilter[0].value = cityId
                }
                return {filters: newFilters, operator: 'and'}
            } else {
                let noCityFilter = newFilters.filter(value => value.field !== 'cityId');
                return {filters: noCityFilter, operator: 'and'}
            }
        } else {
            console.error("Incorrect input filter value for date range")
        }
        return {filters:[], operator: 'and'}
    }

    const cityFilterHandler = (cityId: number) => {
        const filter = insertCityFilter(cityId, dateRangeFilter);
        setRangeFilter(filter)
    }

    async function handleApplyFiltersClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>){
        await fetchOperations({filter: dateRangeFilter})
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{mt:1, justifyContent:'space-between'}}>
                <Box sx={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton/>
                    <GridToolbarExport />
                    <DateTimeRange fieldName={"dateTime"} filters={dateRangeFilter} onTimeRangeChange={rangeFilterHandler}/>
                    <CitySelect onCityChange={cityFilterHandler} filters={dateRangeFilter}/>
                    <Button variant="outlined" size={'small'} sx={{ ml: 3, height:'30px'}} startIcon={<FilterAltIcon/>}
                            onClick={ handleApplyFiltersClick }>Застосувати</Button>
                </Box>

            </GridToolbarContainer>
        );
    }

    return (
        <Box sx={{width: '100%', height: fullHeight + 'px'}}>
            <StyledOperationTable
                sx={{ [`& .${gridClasses.cell}`]: { py: 0.5, }, }}
                getRowHeight={() => 'auto'}
                rows={ report }
                columns={columns}
                loading={isPending}
                density="compact"
                autoHeight={isAutoHeight}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'dateTime', sort: 'asc' }],
                    },
                }}
                hideFooter={true}
                hideFooterPagination={true}
                hideFooterSelectedRowCount
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                    toolbar: CustomToolbar,
                }}
                columnVisibilityModel={columnVisibleModel}
                onColumnVisibilityModelChange={setColumnVisibleModel}
                getRowClassName={styles}
            />
        </Box>
    );
}