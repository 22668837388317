import React, {useState} from 'react'
import {Box, Tab} from "@mui/material";
import OrdersReportTable from "../components/order/OrdersReportTable";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import DistributedOrdersReportTable from "../components/order/DistributedOrdersReportTable";

export default function OrdersReportPage({pageHeight}:{pageHeight: number}) {
    const [tabIndex, setTabIndex] = useState<string>("orders")
    const tabHeight: number = 48
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabIndex(newValue)
    };

    return (
        <Box sx={{ width: '100%', height:pageHeight + 'px', typography: 'body1' }}>
            <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', ml:3, mr:3, height: tabHeight + 'px' }} aria-label={'test'}>
                    <TabList onChange={handleChange} aria-label="settings tab" textColor="secondary"
                             indicatorColor="secondary">
                        <Tab label={'Замовлення'} value="orders" sx={{color:'primary.main'}} />
                        <Tab label={'Розподілені замовлення'} value="distributedOrders" sx={{color:'primary.main'}} />
                    </TabList>
                </Box>
                <TabPanel value="orders" sx={{p:0}}>
                    <OrdersReportTable fullHeight={pageHeight -  tabHeight} isAutoHeight={false}/>
                </TabPanel>
                <TabPanel value="distributedOrders" sx={{p:0}}>
                    <DistributedOrdersReportTable fullHeight={pageHeight -  tabHeight} isAutoHeight={false}/>
                </TabPanel>

            </TabContext>

        </Box>
    );
}
