import {config} from "../ApiConstants";
// import axios from "axios";
import {useAppDispatch} from "../hooks/appHooks";
import {AxiosError} from "axios/index";
import {setError} from "../reducers/message/globalMessageSlice";
import {IRequestQueryMultiple} from "../model/pageRequestQuery/IRequestQueryMultiple";
import {IFinReport, IFinReportPage} from "../model/finReport/IFinReport";
import axiosApi from "../axiosApi/axiosConfig";
import {ICity} from "../model/city/ICity";
import {ICitySettings} from "../model/city/ICitySettings";
import {IDistributionOrdersSettings} from "../model/order/IDistributionOrdersSettings";

export function useDistributionOrdersController(){
    const dispatch = useAppDispatch()

    async function getDistributionSettings(id:number): Promise<IDistributionOrdersSettings | undefined>{
        try{
            const response = await axiosApi.get<IDistributionOrdersSettings>(config.url.DISTRIBUTION_ORDERS + '/settings/' + id)
            return response.data
        }catch (e : unknown) {
            const error = e as AxiosError
            dispatch(setError({title:'Помилка отримання налаштувань розподілу замовлень для міста.', message:(error.response?.data as any).message}))
            return undefined
        }
    }

    async function updateSettings(settings: IDistributionOrdersSettings): Promise<IDistributionOrdersSettings>{
        const response = await axiosApi.patch<IDistributionOrdersSettings>(config.url.DISTRIBUTION_ORDERS + '/settings', settings)
        return response.data
        // try{
        //     const response = await axiosApi.patch<ICitySettings>(config.url.CITY + '/settings', settings)
        //     return response.data
        // }catch (e : unknown) {
        //     const error = e as AxiosError
        //     dispatch(setError({title:'Помилка оновлення налаштувань для міста.', message:(error.response?.data as any).message}))
        // }
    }

    return {
        getDistributionSettings,
        updateSettings,
    }

}